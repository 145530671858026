import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

const SeatConfigImagePreview = ({imagePath, showThumbnail}: {imagePath: string, showThumbnail: boolean}) => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{ m: 2 }} />

            {showThumbnail &&
                <img
                    src={imagePath}
                    onClick={handleClickOpen}
                    alt="Seat configuration thumbnail"
                    style={{
                        width: "50px",
                        maxHeight: "50px",
                        height: "auto",
                        objectFit: "contain",
                        cursor: "pointer",
                    }}
                />
            }
            {!showThumbnail &&
                <Button
                    size="small"
                    variant={"contained"}
                    fullWidth
                    onClick={handleClickOpen}
                >
                    {t("meeting_room_config_show_seat_config")}
                </Button>
            }
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <img
                        src={imagePath}
                        alt="Seat configuration"
                        style={{
                            display: "block",
                            width: '100%'
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleClose}
                        autoFocus
                    >
                        {t("meeting_room_config_close_modal")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SeatConfigImagePreview;
