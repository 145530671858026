import React, {useEffect, useState} from 'react'
import Monitor from './seatRenderComponents/Monitor'
import Chair from './seatRenderComponents/Chair'
import DockingStation from './seatRenderComponents/DockingStation'
import AdjustableHeight from "./seatRenderComponents/AdjustableHeight";
import BookerName from "./seatRenderComponents/BookerName";
import Owner from "./seatRenderComponents/Owner";
import Table from "./seatRenderComponents/Table";
import ExternalBooking from "./seatRenderComponents/ExternalBooking"
import {Booking, Inventory} from "../../API";
import TableNumber from "./seatRenderComponents/TableNumber";
import {useFilterContext} from "../../hooks/useFilterContext";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import MonitorIcon from "../../styles/MonitorIcon";
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import DockingIcon from "../../styles/DockingIcon";
import OwnerIcon from "../../styles/OwnerIcon";
import ExternalBookingIcon from "../../styles/ExternalBookingIcon";
import AdjustableTableIcon from "../../styles/AdjustableTableIcon";
import {SeatBookings} from "../../types/SeatBookinType";
import {useUpdateIsFiltered} from "../../hooks/useUpdateIsFiltered";
import {useUpdateBookingInfos} from "../../hooks/useUpdateBookingInfos";
import {useSeatInventoryLists} from "../../hooks/useSeatInventoryList";
import {useGetFreeTimeWindows} from "../../hooks/useGetFreeTimeWindows";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import useSeatColor from "../../hooks/useSeatColor";
import {useTranslation} from "react-i18next";

dayjs.extend(duration);

export interface Position {
    x: string
    y: string
    transform: string | undefined
}

export interface SeatInterface {
    seatID: string
    seatPosition: Position
    tablePosition: Position
    bookerNamePosition: Position
    tableTextPosition: Position
    neighborhoodId: string
}

interface Props {
    onClick: (bookings: SeatBookings) => void
    seatBookings: SeatBookings
    isTimeBookingActive: boolean
    elementPositions?: ElementPositions
    hasAccessToSeatByNeighborhood: boolean | undefined
    isBookable: boolean
}

type Coordinates = {
    x: number,
    y: number
}

type ElementPositions = {
    chair: Coordinates,
    table: Coordinates,
    dockingStation: Coordinates,
    monitor: Coordinates,
    adjustableHeight: Coordinates,
    owner: Coordinates,
    externalBooking: Coordinates
}

// x and y have fixed values for relative position in parent seat component
// DO NOT CHANGE X & Y variables - or expect render problems
const defaultElements: ElementPositions = {
    adjustableHeight: {x: 25, y: -2},
    monitor: {x: 14, y: -7},
    owner: {x: -221, y: -94},
    dockingStation: {x: -18, y: -14},
    chair: {x: 16.5, y: 24},
    table: {x: 0, y: 0},
    externalBooking: {x: 17.9, y: 21}
}

function SeatComponent(props: Props) {
    const [isMouseOverAdjustableHeight, setIsMouseOverAdjustableHeight] = useState(false);
    const [isMouseOverMonitor, setIsMouseOverMonitor] = useState(false);
    const [isMouseOverDockingStation, setIsMouseOverDockingStation] = useState(false);
    const [isMouseOverOwner, setIsMouseOverOwner] = useState(false);
    const [isMouseOverExternalBooking, setIsMouseOverExternalBooking] = useState(false);
    const [isMouseOverChair, setIsMouseOverChair] = useState(false);
    const [isMouseOverBookingName, setIsMouseOverBookingName] = useState(false);
    const [isHovered, setHover] = useState(false);

    const [monitorList, setMonitorList] = useState<Inventory[]>([]);
    const [dockingList, setDockingList] = useState<Inventory[]>([]);
    const [isHeightAdjustable, setIsHeightAdjustable] = useState<boolean>(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [hasOwner, setHasOwner] = useState(false);
    const [hasForeignBookings, setHasForeignBookings] = useState<boolean>(false);
    const [bookerName, setBookerName] = useState<{ firstName: string, lastName: string, name?: string }>({firstName: "", lastName: ""})

    const {seatBookings, hasAccessToSeatByNeighborhood, isBookable} = props
    const seatPosition = seatBookings.seat.seatPosition
    const tablePosition = seatBookings.seat.tablePosition
    const bookerNamePosition = seatBookings.seat.bookerNamePosition

    const {setItemsToDisplay, currentUserID} = useRoomPlanContext()

    const bookingInfos = useUpdateBookingInfos(seatBookings.bookings, props.isTimeBookingActive);
    const seatBookingTimeWindow = useGetFreeTimeWindows(bookingInfos, props.isTimeBookingActive, "08:00", "18:00");
    const seatBookingState = useSeatColor(seatBookingTimeWindow, currentUserID, props.seatBookings.bookings, props.hasAccessToSeatByNeighborhood, isBookable, props.isTimeBookingActive);

    const {
        selectedMonitor,
        selectedDockingstation,
        showHeightAdjustableDesks,
        seatConfigurationList,
        monitorOptions,
        dockingStationOptions,
        allInventories
    } = useFilterContext()


    let elementPositions: ElementPositions

    if (!props.elementPositions) {
        elementPositions = defaultElements
    } else {
        elementPositions = {...defaultElements, ...props.elementPositions};
    }

    useUpdateIsFiltered(selectedMonitor, selectedDockingstation, monitorList, dockingList, setIsFiltered, showHeightAdjustableDesks, isHeightAdjustable);


    useSeatInventoryLists(setIsHeightAdjustable, seatConfigurationList, props.seatBookings.seat.seatID, setMonitorList, setDockingList, monitorOptions, dockingStationOptions, allInventories);

    useEffect(function updateHasForeignBooking() {
        setHasForeignBookings(seatBookings.bookings.some((booking: Booking) => {
            return !!booking.bookingFor
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seatBookings.bookings.length])

    useEffect(function updateBookerName() {
        if (props.isTimeBookingActive && bookingInfos.length > 1 && props.hasAccessToSeatByNeighborhood && isBookable) {
            return setBookerName({
                firstName: t("alt_first_name_multiple_bookings_same_seat"),
                lastName: t("general_booking-plural")
            })
        }
        if (bookingInfos.length === 1 && props.hasAccessToSeatByNeighborhood && isBookable) {
            return setBookerName({firstName: bookingInfos[0].firstName, lastName: bookingInfos[0].lastName, name: bookingInfos[0].name ?? ""})
        }
        setBookerName({firstName: "", lastName: ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingInfos.length, props.isTimeBookingActive])

    useEffect(function updateOwner() {
        let seatConfig = seatConfigurationList.find(seatConfig => seatConfig.seatName === props.seatBookings.seat.seatID)
        if (seatConfig === undefined || seatConfig === null || seatConfig.owner === "") {
            setHasOwner(false);
        } else {
            setHasOwner(true);
        }
    }, [props.seatBookings.seat.seatID, seatConfigurationList])

    useEffect(function showMonitorMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverMonitor && monitorList.length > 0) {
            monitorList.forEach(monitor => mouseOverItems.push({
                id: "",
                icon: MonitorIcon({viewBox: "0 0 6.35 6.35"}),
                text: monitor.name,
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false,
            }));
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isMouseOverMonitor, monitorList, monitorList.length])

    useEffect(function showDockingMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverDockingStation && dockingList.length > 0) {
            dockingList.forEach(dockingStation => mouseOverItems.push({
                id: "",
                icon: DockingIcon({viewBox: "0 0 6.35 6.35"}),
                text: dockingStation.name,
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false,
            }));
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isMouseOverDockingStation, dockingList, dockingList.length])

    useEffect(function showOwnerMouseOver() {
        function getOwner(): string {
            let seatConfig = seatConfigurationList.find(seatConfig => seatConfig.seatName === props.seatBookings.seat.seatID)
            if (seatConfig !== undefined && seatConfig !== null && seatConfig.owner) {
                return seatConfig.owner;
            }
            return "";
        }

        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverOwner && hasOwner) {
            mouseOverItems = [{
                id: "",
                icon: OwnerIcon({viewBox: "0 0 450 450"}),
                text: getOwner(),
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false,
            }];
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isMouseOverOwner, hasOwner, seatConfigurationList, props.seatBookings.seat.seatID])

    useEffect(function showMultipleBookingsMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (props.isTimeBookingActive && isMouseOverChair && bookingInfos.length > 0 && isBookable) {
            bookingInfos.forEach((bookingInfo) => {
                if (bookingInfo.foreignBooking) {
                    mouseOverItems.push({
                        id: "",
                        icon: ExternalBookingIcon({viewBox: "0 0 100 100"}),
                        text: bookingInfo.firstName + " " + bookingInfo.lastName + ": " + bookingInfo.timeStart.format("HH:mm") + "\u202F\u2013\u202F" + bookingInfo.timeEnd.format("HH:mm") + " – " + bookingInfo.foreignBookerName,
                        textOptional: bookingInfo.firstName + " " + bookingInfo.lastName + " – " + bookingInfo.foreignBookerName,
                        isHint: undefined,
                        isMeetingRoom: false,
                    })
                } else {
                    const bookerName: string = bookingInfo.firstName || bookingInfo.lastName || bookingInfo.name? bookingInfo.firstName + " " + bookingInfo.lastName
                        : t("anonymous")
                    mouseOverItems.push({
                        id: "",
                        icon: OwnerIcon({viewBox: "0 0 450 450"}),
                        text: bookerName + ": " + bookingInfo.timeStart.format("HH:mm") + "\u202F\u2013\u202F" + bookingInfo.timeEnd.format("HH:mm"),
                        textOptional: bookingInfo.firstName + " " + bookingInfo.lastName,
                        isHint: undefined,
                        isMeetingRoom: false,
                    })
                }
            })
        }
        setItemsToDisplay(mouseOverItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingInfos.length, isMouseOverChair, props.isTimeBookingActive, setItemsToDisplay])

    useEffect(function showForeignBookingMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverExternalBooking && props.seatBookings.bookings[0].bookingFor && props.seatBookings.bookings[0].bookingFor !== "" && isBookable) {
            mouseOverItems = [{
                id: "",
                icon: ExternalBookingIcon({viewBox: "0 0 100 100"}),
                text: props.seatBookings.bookings[0].bookerName,
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false
            }];
        }
        setItemsToDisplay(mouseOverItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setItemsToDisplay, isMouseOverExternalBooking, props.seatBookings.bookings.length])

    const {t} = useTranslation();
    useEffect(function showAdjustableHeightMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverAdjustableHeight && isHeightAdjustable) {
            mouseOverItems = [{
                id: "",
                icon: AdjustableTableIcon({viewBox: "0 0 32 32"}),
                text: t("seat-adjustable-height-hover"),
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false
            }]
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isHeightAdjustable, isMouseOverAdjustableHeight])

    useEffect(function showBookingNameMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        const name: string = bookerName.firstName || bookerName.lastName || bookerName.name? bookerName.firstName + " " + bookerName.lastName
            : t("anonymous")
        if (isMouseOverBookingName && isBookable) {
            mouseOverItems = [{
                id: "",
                icon: OwnerIcon({viewBox: "0 0 450 450"}),
                text: name,
                textOptional: null,
                isHint: undefined,
                isMeetingRoom: false,
            }]
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, bookerName.firstName, bookerName.lastName, isMouseOverBookingName])

    function handleOnClickSeat(seatBookings: SeatBookings) {
        if (isBookable && hasAccessToSeatByNeighborhood) {
            props.onClick(seatBookings)
        }
    }

    return (
        <g className="seat" id={props.seatBookings.seat.seatID} transform={seatPosition.transform}
           onClick={() => handleOnClickSeat(seatBookings)}
           onMouseEnter={() => setHover(true)}
           onMouseLeave={() => setHover(false)}>
            <svg x={seatPosition.x} y={seatPosition.y}>
                <g className={"table"}>
                    <svg x={tablePosition.x} y={tablePosition.y}
                         className={seatBookingState}>
                        <Table elementPosition={elementPositions.table}
                               isFiltered={isFiltered}
                               hasAccess={props.hasAccessToSeatByNeighborhood}
                               isBookable={isBookable}
                        />
                        {bookingInfos.length > 0 ?
                            <Chair elementPosition={elementPositions.chair} setMouseOver={setIsMouseOverChair}/> :
                            <Chair elementPosition={elementPositions.chair} setMouseOver={() => {
                            }}/>
                        }
                        {isHeightAdjustable &&
                            <AdjustableHeight elementPosition={elementPositions.adjustableHeight}
                                              setMouseOver={setIsMouseOverAdjustableHeight}
                                              seatInterface={props.seatBookings.seat}/>}
                        {hasOwner && isBookable &&
                            <Owner elementPosition={elementPositions.owner} setMouseOver={setIsMouseOverOwner}
                                   seatInterface={props.seatBookings.seat}/>
                        }
                        {hasForeignBookings && bookingInfos.length < 2 &&
                            <ExternalBooking elementPosition={elementPositions.externalBooking}
                                             setMouseOver={setIsMouseOverExternalBooking}/>}
                        {dockingList.length > 0 &&
                            <DockingStation elementPosition={elementPositions.dockingStation}
                                            setMouseOver={setIsMouseOverDockingStation}
                                            seatInterface={props.seatBookings.seat}/>}
                        {monitorList.length > 0 &&
                            <Monitor seatInterface={props.seatBookings.seat}
                                     elementPosition={elementPositions.monitor}
                                     setMouseOver={setIsMouseOverMonitor}/>}
                    </svg>
                </g>
                <TableNumber seatComponent={props.seatBookings.seat} isHovered={isHovered}/>
                <BookerName bookerNamePosition={bookerNamePosition}
                            bookerFirstName={bookerName.firstName}
                            bookerLastName={bookerName.lastName}
                            bookerName={bookerName.name}
                            seatOwner={seatConfigurationList.find(seatConfig => seatConfig.seatName === props.seatBookings.seat.seatID)?.owner || undefined}
                            setMouseOver={setIsMouseOverBookingName}
                />
            </svg>
        </g>
    )
}

export default SeatComponent;
